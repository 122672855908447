<template>
  <v-card data-cy="create-user-card" v-show="visible">
    <v-card-title class="headline sdark">
      <v-layout align-center>
        <v-flex>
          <span class="primary--text text-uppercase font-weight-medium"
            >create user</span
          >
        </v-flex>
        <v-flex shrink>
          <v-btn
            color="primary"
            icon
            flat
            @click.native="close"
            data-cy="close"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-container grid-list-md>
        <v-layout row wrap align-center>
          <v-flex xs12>
            <v-alert :value="error" outline color="primary">
              {{ error }}
            </v-alert>
          </v-flex>
          <v-flex xs12>
            <d-form
              v-model="valid"
              @onSubmit="save"
              @onCancel="close"
              includeCancel
              v-bind="{ status, error }"
            >
              <v-text-field
                color="primary"
                name="name"
                label="Name"
                id="name"
                v-model="newUser.name"
                :rules="[rules.required, rules.minName]"
              ></v-text-field>
              <v-text-field
                color="primary"
                name="email"
                label="Email"
                :rules="[...rules.emailRules, uniqueEmail]"
                id="email"
                type="email"
                v-model="newUser.email"
                @input="validateUniqueEmail()"
              />
              <v-text-field
                color="primary"
                name="username"
                label="Username"
                id="username"
                :rules="[rules.required, rules.minName, uniqueUsername]"
                v-model="newUser.username"
                @input="validateUniqueUsername()"
              />
              <v-text-field
                ref="password"
                name="password"
                label="Password"
                data-vv-name="password"
                data-vv-delay="300"
                :rules="[...rules.validPassword, passwordValidation]"
                v-model="newUser.password"
                :append-icon="
                  isPasswordVisible ? 'visibility' : 'visibility_off'
                "
                @click:append="isPasswordVisible = !isPasswordVisible"
                :type="isPasswordVisible ? 'text' : 'password'"
                @input="validatePasswords('password')"
              />
              <v-text-field
                label="Password Confirmation"
                name="password_confirmation"
                autocomplete="new-password"
                data-vv-name="password_confirmation"
                data-vv-delay="300"
                target="password"
                :rules="[rules.required, passwordConfirmationValidation]"
                v-model="newUser.password_confirmation"
                :append-icon="
                  isConfirmationVisible ? 'visibility' : 'visibility_off'
                "
                @click:append="isConfirmationVisible = !isConfirmationVisible"
                :type="isConfirmationVisible ? 'text' : 'password'"
                @input="validatePasswords('password_confirmation')"
              />
              <v-select
                v-if="isAdmin"
                name="role"
                label="Role"
                v-model="newUser.role"
                :items="roles"
                data-cy="select-role"
              />
              <SubHeader label="Planning Tool" />
              <v-tooltip
                  :open-delay="tooltipDelay"
                  color="sdark"
                  max-width="430"
                  right
              >
                <v-switch
                    slot="activator"
                    class="py-0"
                    label="Show Component List"
                    v-model="newUser.show_plan_part_lists"
                    color="primary"
                    type="Boolean"
                />
                <span>
                  {{
                    newUser.show_plan_part_lists
                        ? 'Hide'
                        : 'Show'
                  }}
                  button to generate component list
                </span>
              </v-tooltip>
            </d-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { FormMixin } from '@/components/Mixins'
import SubHeader from '../../Forms/SubHeader'

const props = {
  visible: {
    type: Boolean,
    default: false
  },
  status: {
    type: String
  },
  error: {
    type: String
  },
  userOptions: {
    type: Object
  }
}

export default {
  name: 'user-add-card',
  props,
  components: { SubHeader },
  mixins: [FormMixin],
  data: () => ({
    valid: false,
    roles: ['admin', 'user'],
    newUser: {
      name: '',
      email: '',
      company: '',
      phone: '',
      role: 'user',
      username: '',
      password: '',
      password_confirmation: '',
      show_plan_part_lists: false,
    },
    isPasswordVisible: false,
    isConfirmationVisible: false,
    passwordValidation: false,
    passwordConfirmationValidation: false,
    uniqueEmail: false,
    uniqueUsername: false,
  }),
  computed: {
    ...mapState('users', ['user', 'usersList']),
    ...mapGetters('users', ['isAdmin']),
    existingEmails() {
      return this.usersList.map(user => user.email)
    },
    existingUsernames() {
      return this.usersList.map(user => user.username)
    }
  },
  methods: {
    ...mapActions('users', ['CREATE_USER', 'DISMISS']),
    validatePasswords(type) {
      const valid = this.newUser.password === this.newUser.password_confirmation
      if (valid) {
        this.passwordValidation = true
        this.passwordConfirmationValidation = true
        return
      }
      if (type === 'password') {
        this.passwordValidation =
          'The password does not match the password confirmation'
      } else {
        this.passwordConfirmationValidation =
          'The password confirmation does not match the password'
      }
    },
    validateUniqueEmail() {
      this.uniqueEmail =
        !this.existingEmails.includes(this.newUser.email) ||
        'Email already used for another user'
    },
    validateUniqueUsername() {
      this.uniqueUsername =
        !this.existingUsernames.includes(this.newUser.username) ||
        'Username already used for another user'
    },
    async save() {
      const {
        name,
        username,
        password,
        password_confirmation,
        email,
        role,
        show_plan_part_lists,
      } = this.newUser
      const user = {
        name,
        username: username.trim().toLowerCase(),
        password,
        password_confirmation,
        email: email.trim().toLowerCase(),
        role,
        show_plan_part_lists
      }
      await this.CREATE_USER(user)

      if (this.status === 'OK') {
        return setTimeout(() => {
          this.resetData()
          this.close()
        }, 500)
      }
    },
    onDismissed() {
      this.DISMISS()
    },
    close() {
      this.$emit('close')
    },
    resetData() {
      this.newUser = {
        name: '',
        email: '',
        phone: '',
        company: '',
        role: 'user',
        username: '',
        password: '',
        password_confirmation: ''
      }
      this.passwordConfirmationValidation = false
      this.passwordValidation = false
    }
  },
  watch: {
    visible() {
      this.resetData()
      this.newUser.role = 'user'
    },
    userOptions(v) {
      Object.assign(this.newUser, v)
    }
  }
}
</script>
