









import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import UserManagement from '@/components/User/UserManagement.vue'

export default Vue.extend({
  name: 'UserManagementPage',
  components: { UserManagement },
  data: () => ({}),
  computed: {
    ...mapState('sites', ['activeSiteId']),
    ...mapState('users', ['error', 'status', 'userList', 'user']),
    ...mapGetters('users', ['isAdmin'])
  },
  methods: {},
  async mounted(): Promise<void> {
    if (this.isAdmin) {
      await this.$store.dispatch('users/fetchListOfUsers')
    }
  }
})
