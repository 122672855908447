import { IDisplayedSector, IRadiatingCircle } from '@/components/Map/types'

type ZoneType = 'label' | 'alert' | 'disruption'

export const AdminEmail = 'admin@droneshield.com'

export interface IUserZoneSettings {
  displayedSectors: IDisplayedSector[]
  mgrsEnabled: boolean
  milsEnabled: boolean
  radiatingCircle: IRadiatingCircle
  showMarkers: boolean
  visibleZoneTypes: ZoneType[]
  alertZoneFillPattern: boolean
}

export interface IUserSettings {
  displayUnit: string
  mgrsEnabled: boolean
  milsEnabled: boolean
}

export interface IUser {
  id: number
  name: string
  username: string
  encrypted_password: string
  email: string
  role: string
  localRole?: string
  locked_at: any
  created_at: string
  updated_at: string
  deleted_at: any
  theme_name: string
  default_site_id: number
  settings: IUserSettings
  failed_attempts: number
  last_sign_in_ip: string
  sign_in_count: number
  current_sign_in_ip: string
  confirmation_token: string
  reset_password_token: string
  last_sign_in_at: any
  company: string
  fax: string
  confirmed_at: string
  current_sign_in_at: any
  unlock_token: string
  image_url: string
  confirmation_sent_at: any
  reset_password_sent_at: any
  remember_created_at: any
  unconfirmed_email: string
  contact_method: string
  phone: string
  show_plan_part_lists: boolean
  show_plan_prices: boolean
  partner: boolean
}
