




























































































































import Vue from 'vue'
import { UserEditCard, UserSitesCard } from '@/components/User/Cards'
import { mapGetters, mapState, mapActions } from 'vuex'
import SimpleConfirmationDialog from '../../Dialogs/SimpleConfirmationDialog.vue'
import {AdminEmail, IUser} from '@/store/modules/users/types'

const props = {
  users: {
    type: Array,
    default: () => []
  }
}

export default Vue.extend({
  name: 'UserTable',
  props,
  components: {
    SimpleConfirmationDialog,
    UserEditCard,
    UserSitesCard
  },
  data: () => ({
    roles: {
      0: 'manager',
      1: 'site manager',
      2: 'operator',
      3: 'guest'
    },
    search: '',
    passwordDialog: false,
    dialogConfirm: false,
    dialogDeleteConfirm: false,
    editDialog: false,
    userSitesDialog: false,
    deleteText: '',
    deleteDetailText: null,
    selectedUser: null,
    baseHeadersUser: [
      { text: 'Name', value: 'name', align: 'left' },
      { text: 'Username', value: 'username' },
      { text: 'Email', value: 'email' },
      { text: 'Phone', value: 'phone' },
      { text: 'Company', value: 'company' },
      { text: 'Role', value: 'role' }
    ]
  }),
  computed: {
    ...mapState('users', ['status', 'error', 'user']),
    ...mapGetters('users', ['isAuthorized', 'clientById', 'isAdmin']),
    headersUser() {
      let headers = this.baseHeadersUser

      if (this.isAdmin) {
        headers = [
          ...headers,
          ...[
            { text: 'Client', value: 'client' },
            { text: 'Client Id', value: 'clientId', align: 'left' }
          ]
        ]
      }

      headers.push({ text: 'Actions', value: 'name', sortable: false, align: 'left' })

      return headers
    }
  },
  methods: {
    ...mapActions('users', [
      'UPDATE_USER_BY_ADMIN',
      'CLEAR_ERROR',
      'fetchListOfUsers',
      'reactivateUser',
      'deactivateUser',
      'deleteUser'
    ]),
    disableDelete(user: IUser): boolean {
      return this.isCurrentUser(user) || user.email === AdminEmail
    },
    isCurrentUser(user: IUser): boolean {
      return user.id === this.user.id
    },
    clickReset(): void {
      this.passwordDialog = true
    },
    clickEdit(user: IUser): void {
      this.editDialog = true
      this.CLEAR_ERROR()
      this.$bus.$emit('EDIT_USER', { user: JSON.parse(JSON.stringify(user)) })
    },
    clickGuestSites(user: IUser): void {
      this.userSitesDialog = true
      this.$emitter.emit('EDIT_USER_SITES', {
        user: Object.assign({}, user)
      })
    },
    showDeletePopup(user: IUser): void {
      this.$store.commit('users/SET_ERROR', null)
      this.$store.commit('users/SET_STATUS', null)
      this.selectedUser = user
      this.deleteDetailText = `Are you sure you want to delete user?`
      this.dialogDeleteConfirm = true
    },
    showUserActionPopup(user: IUser): void {
      const { username, deleted_at } = user || {}
      this.$store.commit('users/SET_ERROR', null)
      this.$store.commit('users/SET_STATUS', null)
      this.selectedUser = user
      this.deleteText = `${
        deleted_at ? 'Reactivate' : 'Deactivate'
      } User (${username})`
      this.deleteDetailText = `Are you sure you want to ${
        deleted_at ? 'reactivate' : 'deactivate'
      } user?`
      this.dialogConfirm = true
    },
    async updateUser(user: IUser): Promise<void> {
      await this.UPDATE_USER_BY_ADMIN(user)
      if (this.status === 'OK') {
        setTimeout(() => {
          this.editDialog = false
          this.CLEAR_ERROR()
        }, 500)
      } else {
        this.$bus.$emit('APP_MESSAGE', {
          flush: 'manual',
          color: 'error',
          title: 'Error Updating User',
          message: `Reason: ${this.error}`
        })
      }
    },
    async clickDeleteConfirm(): Promise<void> {
      await this.deleteUser(this.selectedUser)
      if (this.status === 'OK') {
        setTimeout(() => (this.dialogDeleteConfirm = false), 500)
      }
    },
    async clickConfirm(): Promise<void> {
      const { deleted_at } = this.selectedUser
      if (deleted_at) {
        // reactivate user
        await this.reactivateUser(this.selectedUser)
      } else {
        // deactivate user
        await this.deactivateUser(this.selectedUser)
      }
      if (this.status === 'OK') {
        setTimeout(() => (this.dialogConfirm = false), 500)
      }
    }
  },
  watch: {
    dialog(v): void {
      this.selectedUser = v ? this.selectedUser : null
    }
  }
})
